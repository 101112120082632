// src/hooks/useAuth.js
import { useState, useEffect } from "react";
import supabase from "../api/supabase";

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    console.log("Checking user");
    const checkUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      console.log("User:", user);
      setCurrentUser(user);
    };
    checkUser();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log("Auth state changed:", event, session);
        setCurrentUser(session?.user ?? null);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  // {2024-09-20 - 13:10} Added email verification function
  const verifyEmailExists = async (email) => {
    const { data, error } = await supabase
      .from("profiles")
      .select("email")
      .eq("email", email)
      .single();

    if (error) {
      return false; // Email doesn't exist
    }
    return true; // Email exists
  };

  // {2024-09-20 - 12:08} Added Forgot Password functionality
  const sendPasswordResetEmail = async (email) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) throw error;
    return data;
  };

  const signInWithGoogle = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${window.location.origin}/api/auth/callback`,
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
        },
      });
      if (error) throw error;
      console.log("Sign in successful:", data.user);
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  // src/hooks/useAuth.js
  const handleOAuthSignIn = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      console.error("Error getting session:", error.message);
      return;
    }

    const { user } = data.session;

    // Check if user is missing required profile fields
    const { data: profileData, error: profileError } = await supabase
      .from("profiles")
      .select("username, favorite_team_id")
      .eq("id", user.id)
      .single();

    if (profileError) {
      console.error("Error fetching profile:", profileError.message);
      return;
    }

    // If the profile is incomplete, redirect to profile completion page
    if (!profileData.username || !profileData.favorite_team_id) {
      window.location.href = "/complete-profile";
    }
  };

  const signIn = async (email, password) => {
    console.log("Signing in with:", { email, password });
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      console.error("Sign in error:", error);
      throw error;
    }
    console.log("Sign in successful:", data.user);
    setCurrentUser(data.user);
  };

  const signUp = async (email, password, username, favoriteTeamId) => {
    console.log("Signing up with:", {
      email,
      password,
      username,
      favoriteTeamId,
    });

    // Validate favoriteTeamId
    if (!favoriteTeamId || isNaN(parseInt(favoriteTeamId))) {
      console.error("Invalid favoriteTeamId:", favoriteTeamId);
      throw new Error("Invalid favorite team selection");
    }

    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          username,
          favorite_team_id: parseInt(favoriteTeamId),
        },
      },
    });

    if (error) {
      console.error("Sign up error:", error);
      throw error;
    }
    console.log("Sign up successful:", data.user);

    // Check if user is created successfully
    if (data.user) {
      // Insert the user profile manually
      const { error: profileError } = await supabase.from("profiles").insert({
        id: data.user.id,
        username: username,
        favorite_team_id: parseInt(favoriteTeamId),
        registration_date: data.user.created_at,
      });

      console.log("Profile created successfully");
      setCurrentUser(data.user);
    } else {
      console.error("User not created");
      throw new Error("Failed to create user");
    }
  };

  const signOut = async () => {
    console.log("Signing out");
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Sign out error:", error);
      throw error;
    }
    console.log("Sign out successful");
    setCurrentUser(null);
  };

  return {
    currentUser,
    signIn,
    signUp,
    signOut,
    signInWithGoogle,
    handleOAuthSignIn,
    sendPasswordResetEmail,
    verifyEmailExists,
  };
};

export default useAuth;
