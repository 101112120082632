// src/context/AppContext.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import useAuth from "../hooks/useAuth";
import useDataFetching from "../hooks/useDataFetching";
import supabase from "../api/supabase";
import { fetchNbaTeams, fetchTeams } from "../api/teams";
import { fetchNbaPlayers, fetchPlayerDetails } from "../api/players";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { toZonedTime, formatInTimeZone } from "date-fns-tz";
import { format, parse, addMonths, subMonths, subHours } from "date-fns"; // {date - time}
import { toast } from "react-toastify"; // {2023-09-21 - 15:30} Added for user notifications
import { useNavigate } from "react-router-dom"; // {2023-09-21 - 15:30} Added for navigation
import { v4 as uuidv4 } from "uuid";

// Create the context
const AppContext = createContext();

// AppProvider component to wrap the application and provide global state
export const AppProvider = ({ children }) => {
  // Authentication state management
  const { currentUser, signIn, signUp, signOut, signInWithGoogle } = useAuth();
  // State hooks for various data types
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [selectedPlayerId, setSelectedPlayerId] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [reviewNumber, setReviewNumber] = useState(1);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { fetchReviews, reviews, sortType, updateSortType, setReviews } =
    useDataFetching();
  const [teamsNeeded, setTeamsNeeded] = useState(false);
  const location = useLocation();

  const [fixtureIds, setFixtureIds] = useState({});
  const [teamPlayers, setTeamPlayers] = useState({});

  const navigate = useNavigate();

  // State for calendar component
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarVisible, setCalendarVisible] = useState(false);

  const checkFixtureStatus = useCallback(async (fixtureId) => {
    try {
      const response = await fetch(
        `https://v3.football.api-sports.io/fixtures?id=${fixtureId}`,
        {
          headers: {
            "x-rapidapi-host": "v3.football.api-sports.io",
            "x-rapidapi-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch fixture status");
      }

      const data = await response.json();
      const fixture = data.response[0]?.fixture;

      if (!fixture) {
        throw new Error("Fixture not found");
      }

      return (
        fixture.status.short === "FT" ||
        fixture.status.long === "Match Finished"
      );
    } catch (error) {
      console.error("Error checking fixture status:", error);
      return false;
    }
  }, []);

  // {2023-09-21 - 15:40} Updated handleVoteClick function
  const handleVoteClick = useCallback(
    async (gameId) => {
      if (!currentUser) {
        setAuthErrorMessage("Please sign in or register to vote.");
        setShowAuthForm(true);
        return;
      }

      const isFixtureFinished = await checkFixtureStatus(gameId);

      if (isFixtureFinished) {
        navigate(`/review/${gameId}`);
      } else {
        return "This Match Is Not Open For Review Yet";
      }
    },
    [
      currentUser,
      checkFixtureStatus,
      navigate,
      setAuthErrorMessage,
      setShowAuthForm,
    ]
  );
  //For the AuthForm component
  useEffect(() => {
    const loadTeams = async () => {
      try {
        const teamsData = await fetchTeams();
        setTeams(teamsData);
      } catch (error) {
        console.error("Error loading teams:", error);
      }
    };

    loadTeams();
  }, []);

  const RELEVANT_LEAGUE_IDS = [4, 39, 253, 140, 524 /* other league IDs */];

  const getLeaguesAndSeason = (zonedDate) => {
    const year = zonedDate.getFullYear();
    const month = zonedDate.getMonth() + 1;

    let season = month >= 6 ? year : year - 1;

    // Return all relevant leagues with the determined season
    return RELEVANT_LEAGUE_IDS.map((leagueId) => ({ leagueId, season }));
  };

  // Cache for storing fetched games
  const gamesCache = useMemo(() => ({}), []);

  // Replace the debouncedFetchGames function with this:
  const fetchGamesFromSupabase = useCallback(
    async (date) => {
      const startOfDay = new Date(date);
      startOfDay.setUTCHours(0, 0, 0, 0);

      const endOfDay = new Date(date);
      endOfDay.setUTCHours(23, 59, 59, 999);

      setIsLoading(true);

      try {
        const { data, error } = await supabase
          .from("games_table_soccer")
          .select("*")
          .gte("date", startOfDay.toISOString())
          .lt("date", endOfDay.toISOString())
          .order("date", { ascending: true });

        if (error) throw error;

        const formattedGames = data.map((game) => ({
          id: game.id,
          name: `${game.home_team_name} vs ${game.away_team_name}`,
          homeTeamKey: game.home_team_name,
          visitorTeamKey: game.away_team_name,
          gameDate: formatInTimeZone(
            new Date(game.date),
            "America/New_York",
            "yyyy-MM-dd HH:mm:ss"
          ),
          venue: game.venue_name,
          fixtureId: game.id,
          season_year: game.season,
          competition_id: game.league_id,
          competition_name: game.league_name,
          referee: game.referee,
          goalsHomeTeam: game.home_team_score,
          goalsAwayTeam: game.away_team_score,
          gameStatusShort: game.status_short,
          gameStatusLong: game.status_long,
        }));

        setGames(formattedGames);
      } catch (error) {
        console.error("Failed to fetch games:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [supabase]
  );

  // Replace the useEffect that calls debouncedFetchGames with this:
  useEffect(() => {
    console.log("Selected date changed:", selectedDate);
    fetchGamesFromSupabase(selectedDate);
  }, [selectedDate, fetchGamesFromSupabase]);

  // Remove the debouncedFetchGames function and its related code

  const handleDateChange = useCallback((newDate) => {
    console.log("Date changed to:", newDate);
    setSelectedDate(newDate);
    setCalendarVisible(false);
  }, []);

  const handleMonthChange = useCallback(
    (increment) => {
      const newDate = increment
        ? addMonths(selectedDate, 1)
        : subMonths(selectedDate, 1);
      console.log("Month changed to:", newDate);
      setSelectedDate(newDate);
    },
    [selectedDate]
  );

  // Function to fetch fixture details from API
  const fetchFixtureDetails = async (fixtureId) => {
    const response = await fetch(
      `https://v3.football.api-sports.io/fixtures?id=${fixtureId}`,
      {
        headers: {
          "x-rapidapi-host": "v3.football.api-sports.io",
          "x-rapidapi-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    const data = await response.json();
    return data.response[0];
  };

  // Function to fetch players for a specific fixture
  const fetchFixturePlayers = async (fixtureId, teamId) => {
    console.log(`Fetching players for fixture ${fixtureId} and team ${teamId}`);
    try {
      const response = await fetch(
        `https://v3.football.api-sports.io/fixtures/players?fixture=${fixtureId}&team=${teamId}`,
        {
          headers: {
            "x-rapidapi-host": "v3.football.api-sports.io",
            "x-rapidapi-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (
        !data.response ||
        !Array.isArray(data.response) ||
        data.response.length === 0
      ) {
        console.error("Unexpected API response format:", data);
        return [];
      }

      const teamData = data.response[0];
      if (!teamData.team || !teamData.players) {
        console.error("Team or players data missing in API response");
        return [];
      }

      const players = teamData.players.map((player) => {
        const statistics = player.statistics[0] || {};
        return {
          id: player.player.id,
          name: player.player.name || "Unknown",
          photo: player.player.photo,
          team_id: teamData.team.id,
          team_key: teamData.team.name,
          minutes: statistics.games?.minutes || 0,
          position: statistics.games?.position || "Unknown",
        };
      });

      console.log("Mapped players:", players);
      return players;
    } catch (error) {
      console.error("Failed to fetch players:", error);
      return [];
    }
  };

  // Function to handle showing auth form
  const handleSetShowAuthForm = (value) => {
    setShowAuthForm(value);
    console.log("showAuthForm changed1:", showAuthForm);
  };

  // Function to handle vote click - 8/12 - 11:42 AM
  ///const handleVoteClick = useCallback(
  ///  (gameId) => {
  ///    const selectedGame = games.find((game) => game.id === gameId);
  ///    if (selectedGame) {
  ///      setSelectedGameId(gameId);
  ///      setFilteredTeams([
  ///        { id: selectedGame.homeTeamId, name: selectedGame.homeTeamKey },
  ///        { id: selectedGame.visitorTeamId, name: selectedGame.visitorTeamKey },
  ///      ]);
  ///    }
  ///  },
  ///  [games]
  ///);

  // Effect to log showAuthForm changes
  useEffect(() => {
    console.log("showAuthForm changed:", showAuthForm);
  }, [showAuthForm]);

  // Effect to determine if teams are needed based on the current route
  useEffect(() => {
    const routesNeedingTeams = ["/matches", "/review"];
    setTeamsNeeded(
      routesNeedingTeams.some((route) => location.pathname.startsWith(route))
    );
  }, [location]);

  // Effect to fetch teams when the date changes
  useEffect(() => {
    const fetchTeamsForDate = async () => {
      if (teamsNeeded) {
        const leaguesAndSeasons = getLeaguesAndSeason(selectedDate);
        const teamsData = await fetchNbaTeams(leaguesAndSeasons);
        const sortedTeams = teamsData.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setTeams(sortedTeams);
        setFilteredTeams(sortedTeams);
      }
    };

    fetchTeamsForDate();
  }, [selectedDate, teamsNeeded]);

  // Effect to fetch players for selected team
  useEffect(() => {
    const fetchPlayersForTeam = async () => {
      console.log("Initial selectedTeamId:", selectedTeamId);
      if (selectedTeamId && selectedGameId) {
        setIsLoading(true);
        try {
          // First, fetch the fixture details to get the team ID
          const response = await fetch(
            `https://v3.football.api-sports.io/fixtures/players?fixture=${selectedGameId}`,
            {
              headers: {
                "x-rapidapi-host": "v3.football.api-sports.io",
                "x-rapidapi-key": process.env.REACT_APP_API_KEY,
              },
            }
          );
          const data = await response.json();

          // Find the team in the response that matches the selectedTeamId (team name)
          const team = data.response.find(
            (team) => team.team.name === selectedTeamId
          );

          if (!team) {
            console.error("Team not found in fixture data");
            return;
          }

          const teamId = parseInt(team.team.id);
          console.log("Found team ID:", teamId);

          // Update the selectedTeamId with the actual ID
          setSelectedTeamId(teamId);

          const playersData = await fetchNbaPlayers(teamId);
          console.log(playersData);
          const sortedPlayers = playersData.sort((a, b) =>
            a.player_name.localeCompare(b.player_name)
          );
          setPlayers(sortedPlayers);
          setFilteredPlayers(sortedPlayers);
        } catch (error) {
          console.error("Error fetching players:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setPlayers([]);
        setFilteredPlayers([]);
      }
    };

    fetchPlayersForTeam();
  }, [selectedTeamId, selectedGameId]);

  // Function to handle game change
  const handleGameChange = useCallback(
    (e) => {
      const gameId = parseInt(e.target.value);
      setSelectedGameId(gameId);
      console.log("Selected Game ID AppContext:", gameId);

      const selectedGame = games.find((game) => game.id === gameId);
      console.log("Selected Game AppContext:", selectedGame);

      if (selectedGame) {
        const filtered = [
          {
            name: selectedGame.homeTeamKey,
          },
          {
            name: selectedGame.visitorTeamKey,
          },
        ];
        setFilteredTeams(filtered);
        console.log("Filtered Teams:", filtered);
      } else {
        setFilteredTeams(teams);
        console.log("Filtered Teams:", teams);
      }
      setSelectedTeamId("");
    },
    [games, teams]
  );

  // Update this function to handle string team names
  const handleTeamChange = useCallback(
    (e) => {
      console.log("Selected team:", e.target.value);
      const teamName = e.target.value;
      const selectedTeam = filteredTeams.find((team) => team.name === teamName);
      if (selectedTeam) {
        setSelectedTeamId(selectedTeam.id);
        console.log("Selected Team ID:", selectedTeam.id);
      } else {
        console.error("Selected team not found in filteredTeams");
        setSelectedTeamId("");
      }
    },
    [filteredTeams]
  );

  // Function to handle form submission
  const handleSubmit = useCallback(
    async (e, playerReviews) => {
      e.preventDefault();
      if (!currentUser) {
        console.error("User not authenticated");
        setShowAuthForm(true);
        return;
      }

      // Find the selected game
      const selectedGame = games.find(
        (game) => game.id === parseInt(selectedGameId)
      );
      if (!selectedGame) {
        console.error("Selected game not found");
        return;
      }

      // Find the selected team
      const selectedTeam = filteredTeams.find(
        (team) => team.id === selectedTeamId
      );
      console.log("Selected Team:", selectedTeam); // Added for debugging

      // If the team is not found in filteredTeams, we should still proceed
      // and attempt to insert it into the database
      if (!selectedTeam) {
        console.warn(
          "Selected team not found in filteredTeams. Attempting to insert into database."
        );
      }

      // Ensure team exists in the database or insert it
      const ensureTeamInDatabase = async () => {
        const { data: existingTeam, error: checkError } = await supabase
          .from("teams_table_soccer")
          .select("id")
          .eq("id", selectedTeamId)
          .single();

        if (checkError && checkError.code !== "PGRST116") {
          console.error("Error checking team existence:", checkError);
          return false;
        }

        if (!existingTeam) {
          // Team doesn't exist, so insert it
          const teamToInsert = selectedTeam || {
            id: selectedTeamId,
            team_name:
              selectedGame.homeTeamKey === selectedTeamId
                ? selectedGame.homeTeamKey
                : selectedGame.visitorTeamKey,
            // You might want to add more fields here if needed
          };

          const { error: insertError } = await supabase
            .from("teams_table_soccer")
            .insert(teamToInsert);

          if (insertError) {
            console.error("Error inserting team:", insertError);
            return false;
          }
          console.log("Team inserted successfully:", teamToInsert);
        }

        return true;
      };

      const teamEnsured = await ensureTeamInDatabase();
      if (!teamEnsured) {
        console.error("Failed to ensure team in database");
        return;
      }

      if (playerReviews.length === 0) {
        console.error("No player reviews to submit");
        return;
      }

      // Fetch player details for all reviewed players
      const playerDetails = await fetchFixturePlayers(
        selectedGameId,
        selectedTeamId
      );
      if (!playerDetails || playerDetails.length === 0) {
        console.error("Player details not found in API");
        return;
      }

      // Insert or update players and their team associations
      for (const player of playerDetails) {
        const { error: upsertPlayerError } = await supabase
          .from("players_table_soccer")
          .upsert({
            id: player.id,
            player_name: player.name,
            position: player.position,
            competition_name: selectedGame.competition_name,
            competition_id: selectedGame.competition_id,
            photo: player.photo,
          });

        if (upsertPlayerError) {
          console.error("Error upserting player:", upsertPlayerError);
          continue;
        }
        console.log(player);
        console.log(selectedTeamId);
        // Upsert player-team association
        const { error: associationError } = await supabase
          .from("player_team_associations")
          .upsert(
            {
              player_id: player.id,
              team_id: player.team_id,
              team_key: player.team_key,
            },
            { onConflict: ["player_id,team_id"] }
          );

        if (associationError) {
          console.error(
            "Error upserting player-team association:",
            associationError
          );
        }
      }

      // Fetch the username from the profiles table
      const { data: profileData, error: profileError } = await supabase
        .from("profiles")
        .select("username")
        .eq("id", currentUser.id)
        .single();

      if (profileError) {
        console.error("Error fetching user profile:", profileError);
        return;
      }

      const username = profileData.username;

      const review_url_id = uuidv4();

      console.log(selectedGame);
      console.log(selectedTeam);
      console.log(selectedTeamId);
      console.log(playerDetails);

      // First, determine which team the players belong to
      const playersTeam = playerDetails[0].team_key; // Assuming all players in the review are from the same team
      console.log(playersTeam);

      // Then, determine the opposing team
      const opposingTeam =
        playersTeam === selectedGame.homeTeamKey
          ? selectedGame.visitorTeamKey
          : selectedGame.homeTeamKey;

      console.log(
        `Players' team: ${playersTeam}, Opposing team: ${opposingTeam}`
      );

      console.log(playerReviews);

      const newReviews = playerReviews.map((review) => {
        const player = playerDetails.find(
          (player) => player.id === review.player_id
        );
        return {
          player_id: review.player_id,
          username: username,
          review_text: review.review_text,
          review_number: review.review_number,
          votesInsightful: 0,
          votesFunny: 0,
          votesThumbsUp: 0,
          opposing_team: opposingTeam,
          game_id: selectedGame.id,
          game_date: selectedGame.gameDate,
          player_team: playersTeam,
          team_id: selectedTeamId,
          competition_id: selectedGame.competition_id,
          competition_name: selectedGame.competition_name,
          season_year: selectedGame.season_year,
          motm: review.motm,
          review_url_id: review_url_id, // {2023-09-21 - 16:22} Added review_url_id to each review
        };
      });
      console.log(newReviews);

      // {2023-09-21 - 16:25} Insert all reviews in a single batch
      const { data: insertedReviews, error: insertReviewsError } =
        await supabase.from("reviews_table_soccer").insert(newReviews).select();

      if (insertReviewsError) {
        console.error("Error inserting reviews:", insertReviewsError);
        toast.error("Failed to submit reviews. Please try again.");
      } else {
        console.log("Reviews inserted successfully:", insertedReviews);
        setReviews((prevReviews) => [...prevReviews, ...insertedReviews]);
        setShowForm(false);
        setSelectedGameId(null);
        setSelectedTeamId("");
        setSelectedPlayerId("");
        setReviewText("");
        setReviewNumber(1);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 2000);

        // {2023-09-21 - 17:20} Prepare data for Lineup component
        const lineupData = {
          teamName: playersTeam,
          teamLogo:
            selectedGame.homeTeamKey === playersTeam
              ? selectedGame.homeTeamLogo
              : selectedGame.visitorTeamLogo,
          opponentName: opposingTeam,
          opponentLogo:
            selectedGame.homeTeamKey === opposingTeam
              ? selectedGame.homeTeamLogo
              : selectedGame.visitorTeamLogo,
          score: `${selectedGame.goalsHomeTeam}-${selectedGame.goalsAwayTeam}`,
          username: username,
          players: newReviews.map((review) => ({
            name:
              playerDetails.find((player) => player.id === review.player_id)
                ?.name || "Unknown",
            position:
              playerDetails.find((player) => player.id === review.player_id)
                ?.position || "Unknown",
            photo:
              playerDetails.find((player) => player.id === review.player_id)
                ?.photo || "",
            rating: review.review_number,
            review: review.review_text,
          })),
        };

        // {2023-09-21 - 16:30} Navigate to the review view page
        // navigate(`/review/view/${review_url_id}`, { state: { lineupData } });
      }
    },
    [
      currentUser,
      games,
      filteredTeams,
      selectedGameId,
      selectedTeamId,
      supabase,
      fetchFixturePlayers,
      navigate, // {2023-09-21 - 16:31} Added navigate to the dependency array
    ]
  );

  // Function to get pill color based on score
  const getPillColor = (score) => {
    if (score > 6) return "green-pill";
    if (score > 3) return "orange-pill";
    return "red-pill";
  };

  // Function to get pill color based on score (out of 100)
  const getPillColor100 = (score) => {
    if (score > 70) return "green-pill";
    if (score > 40) return "orange-pill";
    return "red-pill";
  };

  // Function to handle voting on reviews
  const handleVote = useCallback(
    async (reviewId, voteType) => {
      if (!currentUser) {
        console.error("User must be logged in to vote");
        setAuthErrorMessage("User must be logged in to vote");
        setShowAuthForm(true);
        return;
      }

      // 8/19 - 11:17 AM: Updated vote column mapping to include thumbsUp
      const voteColumnMap = {
        thumbsUp: "votesThumbsUp",
        insightful: "votesInsightful",
        funny: "votesFunny",
      };

      const voteColumn = voteColumnMap[voteType];

      if (!voteColumn) {
        console.error(`Invalid vote type: ${voteType}`);
        return;
      }

      try {
        const { data, error } = await supabase
          .from("reviews_table_soccer")
          .select(voteColumn)
          .eq("id", reviewId)
          .single();

        if (error) throw error;

        const currentVotes = data[voteColumn];

        const { data: updatedReview, error: updateError } = await supabase
          .from("reviews_table_soccer")
          .update({ [voteColumn]: currentVotes + 1 })
          .eq("id", reviewId)
          .select();

        if (updateError) throw updateError;

        fetchReviews();
      } catch (error) {
        console.error("Error handling vote:", error);
      }
    },
    [currentUser, supabase, fetchReviews, setAuthErrorMessage, setShowAuthForm]
  );

  // Provide the context value to child components
  return (
    <AppContext.Provider
      value={{
        currentUser,
        signInWithGoogle,
        signIn,
        signUp,
        signOut,
        teams,
        players,
        reviews,
        games,
        isLoading,
        fetchReviews,
        showForm,
        setShowForm,
        showAuthForm,
        setShowAuthForm: handleSetShowAuthForm,
        selectedGameId,
        selectedTeamId,
        selectedPlayerId,
        reviewText,
        reviewNumber,
        filteredTeams,
        handleGameChange,
        setSelectedTeamId,
        setSelectedPlayerId,
        setReviewText,
        setReviewNumber,
        handleSubmit,
        getPillColor,
        getPillColor100,
        handleVote,
        handleVoteClick,
        checkFixtureStatus,
        handleGameChange,
        sortType,
        setShowSuccessMessage,
        showSuccessMessage,
        supabase,
        filteredPlayers,
        fixtureIds,
        fetchFixturePlayers,
        setReviews,
        selectedDate,
        setSelectedDate,
        calendarVisible,
        setCalendarVisible,
        handleDateChange,
        handleMonthChange,
        authErrorMessage,
        setAuthErrorMessage,
        showAuthForm,
        setShowAuthForm,
        handleTeamChange,
        fetchGamesFromSupabase,
        updateSortType,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
